<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    :style="Style"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CImg :src="$store.state.user.permission.LogoAlt" class="c-sidebar-brand-full" :height="35" />
      <CImg :src="$store.state.user.permission.LogoSM" class="c-sidebar-brand-minimized" :height="35" />
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="$store.state.navigation" />
    <CSidebarNav v-if="$store.state.project.Series === 'mpwei-umeans-ecrm'" class="h-auto d-none d-sm-flex" style="flex: initial">
<!--      <CSidebarNavItem name="feedback" icon="cil-vector" class="a-sidebar-nav-item bg-info text-white" to="/">-->
<!--        <CLink class="c-sidebar-nav-link" to="/feedback">-->
<!--          <CIcon name="cil-chat-bubble" class="c-sidebar-nav-icon" />-->
<!--          {{ $t('Navigation.Message/Feedback') }}-->
<!--        </CLink>-->
<!--      </CSidebarNavItem>-->
      <CSidebarNavItem name="feedback" icon="cil-vector" class="a-sidebar-nav-item bg-success text-white" to="/">
        <CLink class="c-sidebar-nav-link" href="https://page.line.me/umeans" target="_blank">
          <i class="c-sidebar-nav-icon fab fa-line"/>
          LINE 官方帳號
          <!--          <CBadge-->
          <!--            v-if="badge"-->
          <!--            v-bind="Object.assign({}, badge, { text: null })"-->
          <!--          >-->
          <!--            {{badge.text}}-->
          <!--          </CBadge>-->
        </CLink>
      </CSidebarNavItem>
    </CSidebarNav>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from '@/navigation'

export default {
  name: 'TheSidebar',
  data () {
    return {
      Style: {} //todo 調色
    }
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  },
  mounted() {
    // todo 調色
    // this.Style = {
    //   backgroundColor: this.$store.state.themeColor['primary-dark']
    // }
  },
  methods: {
    Init () {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      const AllNavItemTitle = Object.keys(PermissionSession.Navigation)
      nav.forEach((data) => {
        const navName = data._navName.split('/')
        let navTitle, navItem
        if (navName) {
          navTitle = navName[0]
          navItem = navName[1]
        }
        switch (data._name) {
          case 'CSidebarNavTitle':
            if (AllNavItemTitle.includes(navTitle)) {
              this.Navigation[0]._children.push(data)
            }
            break
          case 'CSidebarNavItem':
            if (PermissionSession.Navigation[navTitle] && PermissionSession.Navigation[navTitle].includes(navItem)) {
              this.Navigation[0]._children.push(data)
            }
            break
          case 'CSidebarNavDropdown':
            if (PermissionSession.Navigation[navTitle] && PermissionSession.Navigation[navTitle].includes(navItem)) {
              this.Navigation[0]._children.push(data)
            }
            break
          default:
            this.Navigation[0]._children.push(data)
            break
        }
      })
    }
  }
}
</script>

<style lang="scss">
.a-sidebar-nav-dropdown, .a-sidebar-nav-item {
  &:hover {
    .pin {
      opacity: 1;
    }
  }
}
.pin {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
}
#PinNavigation {
  .pin {
    display: none;
  }
}
</style>
