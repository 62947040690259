<template>
  <CHeader fixed with-subheader light>
    <CToggler
        in-header
        class="ml-3 d-lg-none"
        @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CImg :src="$store.state.user.permission.Logo" :height="40"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/media">
          {{ $t('Navigation.Media') }}
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CButton v-if="parseInt($dayjs().format('HH')) >= 18" color="info" variant="outline" shape="pill" size="sm" class="px-3 mr-2" href="https://page.line.me/mpwei">
        <i class="fas fa-question-circle mr-2" /><span>問題回報</span>
      </CButton>
      <CDropdown id="RealtimeNotify" inNav class="c-header-nav-items d-none d-lg-block" placement="bottom-end" add-menu-classes="pt-0">
        <template #toggler>
          <CHeaderNavLink>
            <CIcon name="cil-bell"/>
            <CBadge v-if="CountUnread > 0" color="danger" shape="pill"><span class="small">{{ CountUnread }}</span>
            </CBadge>
          </CHeaderNavLink>
        </template>
        <CDropdownHeader tag="div" class="text-center" color="light">
          <strong>{{ $t('Navigation.Notification') }}</strong>
        </CDropdownHeader>
        <template v-if="$store.state.notification.length > 0">
          <CDropdownItem
            v-for="(Data, Index) in $store.state.notification"
            :key="Index"
            :class="[{'bg-info text-white unread': !Data.Read}]" @click="HandleNotification(Data, Index)">
            <div class="message">
              <div class="mr-3 float-left">
                <div class="c-avatar">
                  <CIcon :name="NotificationType[Data.Type]" :class="`text-${Data.Type.toLowerCase()}`" />
                </div>
              </div>
              <div class="font-weight-bold">
                <span>{{ Data.Title }}</span>
                <small class="text-muted float-right mt-1">{{ Data.CreateTime }}</small>
              </div>
              <div class="small text-muted text-truncate">{{ Data.Content }}</div>
            </div>
          </CDropdownItem>
          <infinite-loading :identifier="InfiniteId" v-show="$store.state.notification.length > 0" @infinite="ChangePage">
            <template #no-more>
              <CAlert color="secondary" class="mb-0">
                {{ $t('Global.EmptyNotification') }}
              </CAlert>
            </template>
            <template #no-results>
              <CAlert color="secondary" class="mb-0">
                {{ $t('Global.NoMoreNotification') }}
              </CAlert>
            </template>
            <template #error>
              <CAlert color="danger" class="mb-0">
                {{ $t('Global.ErrorLoaded') }}
              </CAlert>
            </template>
            <template #spinner>
              <CSpinner class="mt-2" grow color="dark" />
            </template>
          </infinite-loading>
        </template>
        <template v-else>
          <CDropdownItem :disabled="true">
            {{ $t('System/Basic.NoMessage') }}
          </CDropdownItem>
        </template>
      </CDropdown>
      <CDropdown inNav class="c-header-nav-items mx-2 d-none d-lg-block" placement="bottom-end">
        <template #toggler>
          <CHeaderNavLink>
            <CIcon name="cil-language"/>
          </CHeaderNavLink>
        </template>
        <CDropdownItem v-for="locale in $store.state.allLocale" :key="locale">
          <CIcon :name="localeIcon(locale)" class="mr-2" :active="(locale === $store.state.locale)"/>
          {{ $t('Locale.' + locale) }}
        </CDropdownItem>
      </CDropdown>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
    <Notification :NotificationModal.sync="NotificationModal" :NotificationData.sync="NotificationData"/>
  </CHeader>
</template>

<script>
import i18n from "@/plugins/i18n";
import store from "@/store";

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt: () => import('./TheHeaderDropdownAccnt'),
    Notification: () => import('./Notification'),
    InfiniteLoading: () => import('vue-infinite-loading')
  },
  data() {
    return {
      NotificationModal: false,
      InfiniteId: +new Date(),
      NotificationData: {},
      NotificationType: {
        Success: 'cil-check-circle',
        Warning: 'cil-warning',
        Error: 'cil-x',
        Info: 'cil-info',
      }
    }
  },
  computed: {
    CountUnread() {
      return Object.values(this.$store.state.notification).filter(notify => notify.Read === false).length
    }
  },
  mounted() {
  },
  methods: {
    HandleNotification(data, index) {
      const PromiseData = []
      if (data.Read === false) {
        PromiseData.push(
          this.$store.dispatch('InnerRequest', {
            url: '/notify/ReadManageNotify',
            method: 'post',
            data: {
              ID: data.ID
            }
          }).then(({data}) => {
            this.Loading = false
            this.List = data.Data
            this.Pagination = data.Pagination
          }).catch((err) => {
            this.Loading = false
            throw err
          })
        )
      }
      switch (data.Event.Type) {
        case 'Notification':
          this.NotificationModal = true
          this.NotificationData = Object.assign({}, this.$store.state.notification[index])
          break
        case 'CreateOrder':
          if (data.Event.Handler) {
            this.$router.push('/order/detail/' + data.Event.Handler)
          } else {
            this.$router.push('/order/list')
          }
          break
        case 'ClientAddPost':
        case 'ClientUpdatePost':
          if (data.Event.Handler) {
            this.$router.push('/post/detail/' + data.Event.Handler)
          } else {
            this.$router.push('/post/audit')
          }
          break
      }
      return Promise.all(PromiseData).catch((error) => {
        this.$notify({
          group: 'notify',
          title: i18n.messages[store.state.locale].Message.Error,
          text: i18n.messages[store.state.locale].Message['Global/Unexpected'] + ': ' + error,
          type: 'error'
        })
      })
    },
    ChangePage($state) {
      const Index = this.$store.state.notification.length - 1
      return this.$store.dispatch('GetNotification', this.$dayjs(this.$store.state.notification[Index].CreateTime).unix() - 1).then((dataLength) => {
        if (dataLength > 0) {
          if ($state) $state.loaded()
        } else {
          if ($state) $state.complete()
        }
      })
    },
    localeIcon(locale) {
      let icon
      switch (locale) {
        case 'zh-tw':
          icon = 'cif-tw'
          break
        case 'zh-cn':
          icon = 'cif-cn'
          break
        case 'en-us':
          icon = 'cif-us'
          break
        case 'jp':
          icon = 'cif-jp'
          break
        case 'kr':
          icon = 'cif-kr'
          break
      }
      return icon
    }
  }
}
</script>

<style lang="scss">
#RealtimeNotify  {
  .dropdown-menu {
    max-width: 600px;
    max-height: 366px;
    overflow-y: auto;
    .dropdown-item {
      padding: 0.5rem;
      max-width: 600px;
      min-width: 450px;
      &.unread {
        .c-icon {
          color: #fff!important;
        }
      }
    }
    .message {
      width: 100%;
    }
  }
}
@media (max-width: 576px) {
  #RealtimeNotify  {
    .dropdown-menu {
      max-width: 360px;
      .dropdown-item {
        max-width: 360px;
        min-width: 300px;
      }
    }
  }
}
.c-subheader {
  .breadcrumb {
    .breadcrumb-item {
      a {
        font-weight: bold;
        color: #3399ff;
      }
    }
  }
}
</style>
