<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1 mr-1">&copy; {{new Date().getFullYear()}}</span>
      <a :href="'//' + $store.state.user.permission.Domain" class="font-weight-bold" target="_blank">{{ $store.state.user.permission.ProjectTitle }}</a>
    </div>
    <div class="mfs-auto">
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
